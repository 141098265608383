body {
  margin: 0;
  font-family: 'Montserrat' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-family: 'Montserrat' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1190px;
  width: 100%;
  padding: 0 10px;
  margin: auto;
  box-sizing: border-box;
}

@media only screen and (max-width: 1000px) and (min-width: 600px)  {
  .container {
    padding: 0 46px;
  }
}

h1, h2, h3, h4, h5, p, span, li, ul, ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.text80FW600 {
  font-weight: 600;
  font-size: 80px;
  line-height: 115%;
}

.text80FW700 {
  font-weight: 700;
  font-size: 80px;
  line-height: 115%;
}

.text60 {
  font-weight: 600;
  font-size: 60px;
  line-height: 115%;
}

.text51 {
  font-weight: 600;
  font-size: 51px;
  line-height: 115%;
}

.text48 {
  font-weight: 600;
  font-size: 48px;
  line-height: 115%;
}

.text34 {
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
}

.text22 {
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
}

.text18 {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
}

.text16 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.fw700 {
  font-weight: 700;
}

.fw600 {
  font-weight: 600;
}

@media (max-width: 1000px) {

}

@media (max-width: 650px) {
  .text80FW600 {
    font-size: 36px;
  }

  .text80FW700 {
    font-size: 36px;
  }

  .text60 {
    font-size: 40px;
  }

  .text51 {
    font-size: 36px;
  }

  .text48 {
    font-size: 20px;
  }

  .text34 {
    font-size: 20px;
    line-height: 24px;
  }

  .text22 {
    font-size: 18px;
  }

  .text18 {
    font-size: 16px;
  }

  .text16 {
    font-size: 16px;
    line-height: 20px;
  }
}
